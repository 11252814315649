<template>
  <div id="plang">
    <h2>Programming Languages</h2>
    <Tile :items="langs"/>
    <h4>Learning</h4>
    <Tile :items="learning"/>
  </div>
</template>

<script>

import Tile from '../components/Tile.vue'

export default {
  name: 'Lang',
  components: {
    Tile,
  },
  data() {
    return {
      langs: [
        {
          name: 'javascript',
          fileName: 'javascript-original',
          prettyName: 'Javascript',
        },
        {
          name: 'go',
          fileName: 'go-original',
          prettyName: 'Golang',
        },
        {
          name: 'python',
          fileName: 'python-original',
          prettyName: 'Python',
        },
        {
          name: 'c',
          fileName: 'c-original',
          prettyName: 'C',
        },
        {
          name: 'java',
          fileName: 'java-original',
          prettyName: 'Java',
        },
        {
          name: 'php',
          fileName: 'php-original',
          prettyName: 'PHP',
        },
        {
          name: 'nodejs',
          fileName: 'nodejs-original',
          prettyName: 'NodeJS',
        },
        {
          name: 'vuejs',
          fileName: 'vuejs-original',
          prettyName: 'VueJS',
        },
        {
          name: 'bash',
          fileName: 'bash-original',
          prettyName: 'bash',
        },
        {
          name: 'jamstack',
          fileName: 'jamstack-original',
          prettyName: 'JAM Stack',
        },
      ],
      learning: [
        {
          name: 'rust',
          fileName: 'rust-plain',
          prettyName: 'Rust',
        },
        {
          name: 'lua',
          fileName: 'lua-original',
          prettyName: 'Lua',
        },
      ]
    }
  },
}
</script>

<style scoped>
.reader {
  width: 100%;
  padding: 30px 30px;
  border-radius: 0 0 2rem 0;
}

.container {
  display: flex;
  flex-flow: row wrap;
}

/* .tile { */
/*   margin: 10px; */
/*   height: 100px; */
/*   width: calc( (100% / 6) - 10px ); */
/*   padding: 10px; */
/* } */
</style>
