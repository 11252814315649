<template>
 <div class="container">
   <div v-for="item in items" :key="item.name" class="tile">
     <img
      class="devicon-img"
      :title="item.prettyName"
      :src="`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${item.name}/${item.fileName}.svg`"
      />
      <p>
        {{ item.prettyName }}
      </p>
   </div>
 </div>
</template>

<script>
export default {
  name: 'Tile',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 40px;
  row-gap: 24px;
}

.devicon-img {
  height: 48px;
  width: 48px;
}

.tile {
  margin: 10px;
  text-align: center;
  overflow: wrap;
  width: 84px;
  padding: 10px;
}

.tile:hover p {
  opacity: 1;
  translate: 0 0;
}

.tile p {
  opacity: 0;
  translate: 0 -10px;
  transition: all 0.1s ease;
}

.tile p {
  opacity: 0;
  translate: 0 -10px;
  transition: all 0.1s ease;
}

@media only screen and (min-width: 1024px) {
  .devicon-img {
    height: 64px;
    width: 64px;
  }
}

@media only screen and (min-width: 1440px) {
  .devicon-img {
    height: 80px;
    width: 80px;
  }
  .tile {
    width: 100px;
  }
}
</style>
